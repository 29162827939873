import {Modal, Popover, Toast} from 'bootstrap';

// Helper for fetching CSRF token
export async function getSessionInfo() {
    if (! (window.lindbakSessionInfo && window.lindbakSessionInfo.csrfTokenName && window.lindbakSessionInfo.csrfTokenValue)) {
        const response = await fetch('/actions/users/session-info', {
            headers: {
                'Accept': 'application/json',
            },
        });
        window.lindbakSessionInfo = await response.json();
    }
    return window.lindbakSessionInfo;
}

// Helper for creating POST requests to backend
export async function createPostRequest(url, payload, contentType = 'application/json', signal = null) {
    try {
        const session = await getSessionInfo();
        let requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': contentType,
                'Accept': 'application/json',
                'X-CSRF-Token' : session.csrfTokenValue,
                'X-Requested-With': 'XMLHttpRequest',
            },
            body: contentType === 'application/json' ? JSON.stringify(payload) : payload,
        };
        if( signal !== null ) {
            requestOptions.signal = signal;
        }
        const response = await fetch(url, requestOptions);
        return {
            success: true,
            response: await response.json()
        }
    } catch (e) {
        return {
            success: false,
            response: e
        }
    }
}

export async function postFormData(url, form) {
    if( form instanceof HTMLFormElement ) {
        const formData = new FormData(form);
        const params = new URLSearchParams(formData).toString();
        return await createPostRequest(url, params, 'application/x-www-form-urlencoded');
    } else {
        throw "Parameter has to be instance of HTMLFormElement!";
    }
}

export async function createGetRequest(path, paramsObject = null) {
    try {
        const session = await getSessionInfo();
        let url = path;
        if( paramsObject ) {
            const queryString = new URLSearchParams(paramsObject).toString();
            url = `${path}?${queryString}`;
        }
        
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-CSRF-Token' : session.csrfTokenValue,
                'X-Requested-With': 'XMLHttpRequest',
            }
        });
        return await response.json();
    } catch (e) {
        console.error(e);
        return false;
    }
}

export function addDynamicEventListener(rootElement, eventType, selector, callback, options) {
    const getConditionalCallback = (selector, callback) => {
        return function(e) {
            if (!e.target) {
                return;
            }
            if (e.target.closest(selector)) {
                callback.apply(this, arguments);
            }
        };
    }
    rootElement.addEventListener(eventType, getConditionalCallback(selector, callback), options);
}

export function getClosest(elem, selector) {
    // Get the closest matching element
    for (; elem && elem !== document; elem = elem.parentNode) {
        if (elem.matches(selector)) {
            return elem;
        }
    }
    return null;
}

export function serializeForm(form) {
    let obj = {};
    let formData = new FormData(form);
    for (const key of formData.keys()) {
        obj[key] = formData.get(key);
    }
    return obj;
}

export function createElement(str) {
    const el = document.createElement('div');
    el.innerHTML = str;
    return el.firstElementChild;
}

// expects templates to be inside application/template folder, ideally forms has dynamic ids to prevent creating multiple modals with same id attributes
export async function createModalFromTemplate(modalTemplatePath = '', options = null) {
    const response = await createPostRequest('/modal/get-modal-content', {modalTemplatePath});
    if( response.success && response.response.hasOwnProperty('html') ) {
        const modalElement = createElement(response.response.html);
        document.body.insertAdjacentHTML('beforeend', modalElement.outerHTML);
        let modal;
        if( options !== null ) {
            modal = new Modal(modalElement, options);
        } else {
            modal = new Modal(modalElement);
        }
        try {
            modal._element.addEventListener('hidden.bs.modal', (e) => {
                modal.dispose();
            });
        } catch (e) {
            console.error(e);
        }
        return modal;
    } else {
        console.error(response);
        return false;
    }
}

export function createModal(header = '', body = '', submitButtonText = 'Send Inn', cancelButtonText = '' ) {
    const randomId = `modal__${parseInt(Math.random() * 1000)}`;
    const headerHTML = header.length
        ? `<div class="modal-header">
                <h5 class="modal-title">${header}</h5>
                <button type="button" class="bttn bttn--close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>` 
        :  ``;
    const bodyHTML = body.length
        ? `<div class="modal-body">
                <div class="text">
                    ${body}
                </div>
            </div>`
        : ``;
    const footerSubmitButtonHTML = `<button class="bttn color--color4" type="submit" data-bs-toggle="modal" data-bs-target="#${randomId}" aria-controls="${randomId}">${submitButtonText}</button>`;
    const footerCancelButtonHTML = cancelButtonText.length ? `<button type="button" class="bttn bttn--solid color--color3" data-bs-toggle="modal" data-bs-target="#${randomId}" aria-controls="${randomId}">${cancelButtonText}</button>` : ``;
    
   const modalHTML = `
    <div class="modal fade" id="${randomId}" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                ${headerHTML}
                ${bodyHTML}
                <div class="modal-footer">
                    ${footerSubmitButtonHTML}
                    ${footerCancelButtonHTML}
                </div>
            </div>
        </div>
    </div>`;
   const modalElement = createElement(modalHTML);
   const modal = new Modal(modalElement);
   try {
       modal._element.addEventListener('hidden.bs.modal', (e) => {
           modal.dispose();
       });
   } catch (e) {
       console.error(e);
   }
   return modal;
}

export function createPopover(HTMLElementOrSelector, hideInterval = 1500, onlyShowOnce = false, content = '') {
    let targetElement;
    if( HTMLElementOrSelector instanceof HTMLElement ) {
        targetElement = HTMLElementOrSelector;
    } else {
        targetElement = document.querySelector(HTMLElementOrSelector);
    }
    
    if( targetElement instanceof HTMLElement ) {
        const popover = Popover.getOrCreateInstance(targetElement);
        if( content.length ) {
            popover.setContent({
                '.popover-body': content
            });
        }

        popover.enable();
        
        targetElement.addEventListener('shown.bs.popover', (e) => {
            setTimeout(() => {
                popover.hide();
            }, hideInterval);
        });
        
        if( onlyShowOnce ) {
            targetElement.addEventListener('hidden.bs.popover', (e) => {
                popover.disable();
            });
        }
        return popover;
    }
    throw 'Unable to find HTMLElement to create the Popover!';
}

export function getPopover(HTMLElementOrSelector) {
    let targetElement;
    if( HTMLElementOrSelector instanceof HTMLElement ) {
        targetElement = HTMLElementOrSelector;
    } else {
        targetElement = document.querySelector(HTMLElementOrSelector);
    }
    if( targetElement instanceof HTMLElement ) {
        return Popover.getOrCreateInstance(targetElement);
    }
    throw 'Unable to find HTMLElement to create the Popover!';
}

export function setPopoverContent(popover, content) {
    if( popover instanceof Popover ) {
        popover.setContent({
            '.popover-body': content
        });
    } else {
        throw "Provided object was not a Bootstrap Popover!";
    }
}

export async function showToast(message = '', heading = '', showTime = false, autoHide = true, hideDelay = 3000) {
    const response = await createPostRequest('/modal/get-toast-content', {message, heading, showTime, autoHide, hideDelay});
    if( response.success && response.response.hasOwnProperty('html') ) {
        const toastElement = createElement(response.response.html);
        const toastContainer = document.querySelector('.toast-container');
        if( toastContainer instanceof HTMLElement ) {
            toastContainer.appendChild(toastElement);
            const toast = new Toast(toastElement);
            toast.show();

            toastElement.addEventListener('hidden.bs.toast', () => {
                toast.dispose();
            })
        }
    }
}

export async function includeAsyncScript(src) {
    let scriptLoaded = false;
    const scriptTag = document.createElement('script');
    scriptTag.src = src;
    scriptTag.async = true;
    scriptTag.onload = () => {
        scriptLoaded = true;
    };
    document.body.append(scriptTag);

    while (! scriptLoaded ) {
        await new Promise(r => setTimeout(r, 200));
    }
}

export function getSyncSpinner(header = null) {
    let modalContainer = null;
    if (header === null) {
        modalContainer = document.getElementById('modal__spinner');
    }
    if (modalContainer) {
        const options = {
            keyboard: false, focus: true, backdrop: 'static'
        };
        const modal = Modal.getOrCreateInstance(modalContainer, options);
        modal.handleUpdate();
        return modal;
    }
    return null;
}

export async function getSpinner(header = null) {
    let modalContainer = null;
    if( header === null ) {
       modalContainer = document.getElementById('modal__spinner');
    }
    
    if( modalContainer === null ) {
        return await createSpinner(header);
    } else {
        const options = {
            keyboard: false,
            focus: true,
            backdrop: 'static'
        };
        const modal = Modal.getOrCreateInstance(modalContainer, options);
        modal.handleUpdate();
        return modal;
    }
}

export async function createSpinner(header = null) {
    let modalContainer = null;
    let payload = {
        modalTemplatePath: 'components/modals/_spinner.twig'
    };
    if( header !== null ) {
        payload.header = header;
    }
    const response = await createPostRequest('/modal/get-modal-content', payload);
    if (response.success && response.response.hasOwnProperty('html')) {
        modalContainer = createElement(response.response.html);
        document.body.insertAdjacentHTML('beforeend', modalContainer.outerHTML);
    }

    if ( modalContainer !== null) {
        const options = {
            keyboard: false,
            focus: true,
            backdrop: 'static'
        };
        const modal = Modal.getOrCreateInstance(modalContainer, options);
        modal.handleUpdate();
        return modal;
    }
    console.log("Couldn't create spinner!");
}

export async function updateCartIndicator() {
    const response = await createGetRequest('/webshop/get-number-of-items-in-cart');
    if( response.hasOwnProperty('error') ) {
        console.error(response.error);
    } else if (response.hasOwnProperty('numOfItemsInCart') ) {
        const numOfItems = response.numOfItemsInCart;
        
        const cartIndicator = document.querySelector("header .nav--cart > a");
        if( cartIndicator instanceof HTMLAnchorElement) {
            const numberSpan = cartIndicator.querySelector('span');
            if( numberSpan ) {
                if( numOfItems > 0 ) {
                    numberSpan.textContent = numOfItems.toString();
                    cartIndicator.ariaLabel = `Handlekurv med ${numOfItems} produkter`;
                    cartIndicator.parentNode.classList.add('active');
                }
            }
        }
    }
}

export async function updateWishlistIndicator() {
    const response = await createGetRequest('/webshop/get-number-of-wishlisted-items');
    if( response.hasOwnProperty('error') ) {
        console.error(response.error);
    } else if( response.hasOwnProperty('numOfWishlistedItems') ) {
        const numOfItems = parseInt(response.numOfWishlistedItems);
        const wishlistIndicator = document.querySelector("header .nav--favourites > a");
        if( wishlistIndicator instanceof HTMLAnchorElement) {
            let span = wishlistIndicator.querySelector('span');
            wishlistIndicator.ariaLabel = numOfItems === 1 ? wishlistIndicator.dataset.labelSingular.replace('%d', `${numOfItems}`) : wishlistIndicator.dataset.labelPlural.replace('%d', `${numOfItems}`);
            
            if( numOfItems > 0 ) {
                if( span instanceof HTMLSpanElement ) {
                    span.textContent = numOfItems.toString();
                } else {
                    span = document.createElement('span');
                    span.textContent = numOfItems.toString();
                    wishlistIndicator.appendChild(span);
                }
            } else {
                if( span instanceof HTMLSpanElement ) {
                    span.remove();
                } 
            }
        }
    }
}

export function validateBSForm(formElement) {
    let valid = false;
    if( formElement instanceof HTMLFormElement && formElement.hasAttribute('novalidate') ) {
        formElement.classList.add('was-validated');
        valid = formElement.checkValidity();
    }
    return valid;
}

export function kebab(string) {
    return string.replace(/\s+/g, '-')
        .toLowerCase()
        .replace(/[^\x00-\x7F]/g, '');
}

export function randomString(prefix = '') {
    return Math.random().toString(36).replace('0.',prefix || '');
}